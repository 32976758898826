h1 {
    font-size: 20px;
    color: white
}

h2 {
    font-size: 24px;
    color: white;
    margin-bottom: 0;
}

.App-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Header-language-option {
    margin-bottom: .5em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Header-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Button-wrapper {
    width: 85%;
    margin: .5em auto;
}

.Version-text {
    color: lightgrey;
    font-size: 12px;
    margin-top: 1em;
}