body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Noto Sans Meetei Mayek';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    background-color: darkslateblue;
}

.App {
    padding: 1em;
    min-height: calc(100vh - 2em);
    width: calc(100% - 2em);
}

.App-title {
    font-size: 18px;
    color: #505050;
    text-align: center;
}

.App-logo {
    height: 50px;
    pointer-events: none;
}

.App-header {
    min-height: 100px;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-footer p{
    color: white;
    font-size: 12px;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .App {
        margin: 0 auto;
        width: calc(80% - 2em);
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .App {
        margin: 0 auto;
        width: calc(80% - 2em);
    }
}