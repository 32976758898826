.App-customer-survey {
    background-color: darkslateblue;
    min-height: calc(100vh - 2em);
    padding: 0;
    margin: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.Wrapper-loading {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Wrapper-loading h2 {
    color: white;
    margin-top: 100px;
}

.Wrapper-back-button {
    position: absolute;
    left: 30px;
    bottom: 120px;
}